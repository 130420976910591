import { PROD_SITE } from '@/const/prod.const';
import u from '@/util';
export const appendHost = (
    url: string, 
    isNeedHost:  boolean = false,
    host: string = '',
) => {
    let finalReturnUrl = url;
    if(url?.includes('maomi/mm_web_assets')){
        finalReturnUrl = url;
    }else{
        finalReturnUrl = `/maomi/mm_web_assets/build${PROD_SITE}/${url}`;
    }

    if(isNeedHost){
        finalReturnUrl = host + finalReturnUrl;
    }

    return finalReturnUrl;
}

export const generateFullUrl = (
    url: string,
    encrypted_image_domain: string,
) => {
    let sourcurl = encrypted_image_domain ?? process.env.REACT_APP_STATIC_SOURCE_URL;

    if(!url?.includes('maomi/mm_web_assets')){
        url = `/maomi/mm_web_assets/build${PROD_SITE}/${url}`;
    }

    url = url?.replaceAll('//', '/');
    const vidKeyParam = u.addImgKeyParam(url);
    if(url?.includes(sourcurl)){
        return `${url}${vidKeyParam}`;
    }else{
        return `${sourcurl}${url}${vidKeyParam}`;
    }
}